/* eslint-disable*/
import { styled } from '@mui/material/styles';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  Button,
  Link,
  IconButton
} from '@material-ui/core';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { termArray } from 'feactures/date/dateSlice';
import TitleChangeModal from 'components/atoms/modal/TitleChangeModal';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { selectedMeasure } from 'feactures/measure/newMeasureSlice';

//「？」マークにカーソルが当たったときの吹き出し
export const HtmlTooltip = styled(({ className, placement, ...props }) => (
  <Tooltip
    {...props}
    arrow
    placement={placement}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.paper
    // border: '1px solid #dadde9'
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    maxWidth: 280
  }
}));

const HeaderCell = styled(TableCell)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: 'bold',
  color: '#ffffff',
  textAlign: 'left',
  border: '1px solid white',
  padding: '8px'
  // wordBreak: 'break-all'
}));

const StickyHeaderCell = styled(TableCell)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: 'bold',
  color: '#ffffff',
  backgroundColor: '#728DC4',
  textAlign: 'left',
  border: '1px solid white',
  position: 'sticky',
  left: '-1px',
  zIndex: 2,
  width: '100px',
  padding: '8px',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRight: '1px solid lightGray',
    backgroundColor: '#728DC4',
    zIndex: -1
  }
}));

const StickyCell = styled(TableCell)(({ theme }) => ({
  fontSize: '12px',
  textAlign: 'left',
  border: '1px solid lightGray',
  backgroundColor: '#ffffff',
  position: 'sticky',
  left: '-1px',
  zIndex: 1,
  padding: '0',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRight: '1px solid lightGray'
  },
  '&:hover': {
    backgroundColor: '#e0e0e0'
  }
}));

const BodyCell = styled(TableCell)(({ theme }) => ({
  fontSize: '12px',
  textAlign: 'right',
  border: '1px solid lightGray',
  padding: '8px',
  boxSizing: 'border-box',
  minWidth: '80px'
}));

const StyledTableRow = styled(TableRow)((props) => ({
  height: '35px',
  '&:hover': {
    backgroundColor: '#e0e0e0'
  }
}));

export default function ProductRankingDetailTable({
  expanded,
  setExpanded,
  data,
  itemName
}) {
  const dateArray = useSelector(termArray);
  const [open, setOpen] = useState(false);
  const [modalIndex, setModalIndex] = useState(0);
  const measure = useSelector(selectedMeasure);

  const fontColorList = ['#0f8a3c', '#0f8a3c', '#d12b1f', '#d12b1f'];
  const bgColorList = ['#ffffff', '#E6F7ED', '#ffffff', '#fdf1f0'];

  const handleTitleChangeModal = ({ index }) => {
    setModalIndex(index);
    setOpen(true);
  };

  const BodyCellArray = ({ data: dataList, indicator: indicator }) => {
    return (
      <>
        {dataList.map((listItem, index) =>
          index === 0 ||
          listItem === dataList[index - 1] ||
          (measure === 'rank' && listItem === 0) ? (
            <BodyCell
              onClick={() => {
                if (indicator === 'title') {
                  handleTitleChangeModal({ index });
                }
              }}
              sx={{ cursor: indicator == 'title' ? 'pointer' : 'auto' }}
              key={`${listItem}-${index}`}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <TrendingFlatIcon sx={{ fontSize: '16px', mr: '4px' }} />
                <Typography
                  color={indicator === 'title' ? '#216bff' : 'inherit'}
                >
                  {indicator == 'price' || indicator == 'real-price' ? '¥' : ''}
                  {indicator == 'ranking' && listItem == -99
                    ? '圏外'
                    : indicator == 'ranking' && listItem == 0
                    ? '-'
                    : indicator == 'real-price'
                    ? Math.round(
                        (listItem *
                          (100 -
                            data['coupon'][index] -
                            data['sale'][index] -
                            data['point'][index])) /
                          100
                      ).toLocaleString()
                    : indicator == 'review'
                    ? listItem.toFixed(1)
                    : listItem.toLocaleString()}
                  {indicator == 'point'
                    ? '%'
                    : indicator == 'title'
                    ? '回'
                    : indicator == 'ranking' && listItem > 0
                    ? '位'
                    : ''}
                </Typography>
              </Box>
            </BodyCell>
          ) : listItem < dataList[index - 1] ? (
            <BodyCell
              sx={{
                bgcolor:
                  listItem < dataList[index - 1] * 0.7
                    ? bgColorList[3]
                    : 'none',
                color:
                  listItem < dataList[index - 1] * 0.7
                    ? fontColorList[3]
                    : fontColorList[2],
                borderColor:
                  listItem < dataList[index - 1] * 0.7
                    ? fontColorList[3]
                    : fontColorList[2],
                cursor: indicator == 'title' ? 'pointer' : 'auto'
              }}
              onClick={() => {
                if (indicator === 'title') {
                  handleTitleChangeModal({ index });
                }
              }}
              key={`${listItem}-${index}`}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <TrendingDownIcon sx={{ fontSize: '16px', mr: '4px' }} />
                <Typography>
                  {indicator == 'price' || indicator == 'real-price' ? '¥' : ''}
                  {indicator == 'ranking' && listItem == -99
                    ? '圏外'
                    : indicator == 'ranking' && listItem == 0
                    ? '-'
                    : indicator == 'real-price'
                    ? Math.round(
                        (listItem *
                          (100 -
                            data['coupon'][index] -
                            data['sale'][index] -
                            data['point'][index])) /
                          100
                      ).toLocaleString()
                    : indicator == 'review'
                    ? listItem.toFixed(1)
                    : listItem.toLocaleString()}
                  {indicator == 'point'
                    ? '%'
                    : indicator == 'title'
                    ? '回'
                    : indicator == 'ranking' && listItem > 0
                    ? '位'
                    : ''}
                </Typography>
              </Box>
            </BodyCell>
          ) : (
            <BodyCell
              sx={{
                bgcolor:
                  listItem > dataList[index - 1] * 1.3
                    ? bgColorList[1]
                    : 'none',
                color:
                  listItem > dataList[index - 1] * 1.3
                    ? fontColorList[1]
                    : fontColorList[0],
                borderColor:
                  listItem > dataList[index - 1] * 1.3
                    ? fontColorList[1]
                    : fontColorList[0],
                cursor: indicator == 'title' ? 'pointer' : 'auto'
              }}
              onClick={() => {
                if (indicator === 'title') {
                  handleTitleChangeModal({ index });
                }
              }}
              key={`${listItem}-${index}`}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <TrendingUpIcon sx={{ fontSize: '16px', mr: '4px' }} />
                <Typography>
                  {indicator == 'price' || indicator == 'real-price' ? '¥' : ''}
                  {indicator == 'ranking' && listItem == -99
                    ? '圏外'
                    : indicator == 'ranking' && listItem == 0
                    ? '-'
                    : indicator == 'real-price'
                    ? Math.round(
                        (listItem *
                          (100 -
                            data['coupon'][index] -
                            data['sale'][index] -
                            data['point'][index])) /
                          100
                      ).toLocaleString()
                    : indicator == 'review'
                    ? listItem.toFixed(1)
                    : listItem.toLocaleString()}
                  {indicator == 'point'
                    ? '%'
                    : indicator == 'title'
                    ? '回'
                    : indicator == 'ranking' && listItem > 0
                    ? '位'
                    : ''}
                </Typography>
              </Box>
            </BodyCell>
          )
        )}
      </>
    );
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: '10px',
          width: 'calc(100vw - 355px)',
          height: expanded ? 'auto' : '0px',
          overflowY: 'hidden',
          transition: '0.3s',
          mx: 'auto'
        }}
      >
        <Table
          sx={{
            tableLayout: 'fixed',
            overflowX: 'auto',
            borderCollapse: 'separate',
            borderSpacing: '0.5px',
            width: 'auto'
          }}
        >
          <TableHead sx={{ bgcolor: '#728DC4' }}>
            <TableRow>
              <StickyHeaderCell sx={{ width: '100px' }}>
                カテゴリ
              </StickyHeaderCell>
              <StickyHeaderCell sx={{ left: '98px', width: '130px' }}>
                施策
              </StickyHeaderCell>
              {dateArray.map((day, index) => (
                <HeaderCell key={day}>{day}</HeaderCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow>
              <StickyCell colSpan={2}>
                <Box display={'flex'} sx={{ height: '100%' }}>
                  <Box
                    sx={{
                      borderRight: '1px solid lightGray',
                      width: '100px',
                      height: '35px',
                      lineHeight: '35px',
                      px: 1
                    }}
                  >
                    ランキング
                  </Box>
                  <Box
                    sx={{
                      width: '130px',
                      height: '35px',
                      lineHeight: '35px',
                      px: 1
                    }}
                  >
                    　-　
                  </Box>
                </Box>
              </StickyCell>
              <BodyCellArray data={data.rank} indicator={'ranking'} />
            </StyledTableRow>
            <StyledTableRow sx={{ bgcolor: '#f5f5f5' }}>
              <StickyCell colSpan={2} sx={{ bgcolor: '#f5f5f5' }}>
                <Box display={'flex'} sx={{ height: '100%' }}>
                  <Box
                    sx={{
                      borderRight: '1px solid lightGray',
                      width: '100px',
                      height: '35px',
                      lineHeight: '35px',
                      px: 1
                    }}
                  >
                    価格
                  </Box>
                  <Box
                    sx={{
                      left: '98px',
                      width: '130px',
                      height: '35px',
                      lineHeight: '35px',
                      px: 1
                    }}
                  >
                    価格
                  </Box>
                </Box>
              </StickyCell>
              <BodyCellArray data={data.price} indicator={'price'} />
            </StyledTableRow>
            <StyledTableRow>
              <StickyCell colSpan={2}>
                <Box display={'flex'} sx={{ height: '100%' }}>
                  <Box
                    sx={{
                      borderRight: '1px solid lightGray',
                      width: '100px',
                      height: '35px',
                      lineHeight: '35px',
                      px: 1
                    }}
                  >
                    価格
                  </Box>
                  <Box
                    sx={{
                      left: '98px',
                      width: '130px',
                      height: '35px',
                      lineHeight: '35px',
                      px: 1
                    }}
                  >
                    実質価格
                    <HtmlTooltip
                      placement="top"
                      title={
                        <span style={{ whiteSpace: 'pre-line' }}>
                          通常価格からクーポン等の割引額を差し引いたもの
                        </span>
                      }
                    >
                      <span>
                        <IconButton>
                          <InfoIcon
                            style={{
                              fontSize: '18px',
                              color: '#728DC4'
                            }}
                          />
                        </IconButton>
                      </span>
                    </HtmlTooltip>
                  </Box>
                </Box>
              </StickyCell>
              <BodyCellArray data={data.price} indicator={'real-price'} />
            </StyledTableRow>
            <StyledTableRow sx={{ bgcolor: '#f5f5f5' }}>
              <StickyCell colSpan={2} sx={{ bgcolor: '#f5f5f5' }}>
                <Box display={'flex'} sx={{ height: '100%' }}>
                  <Box
                    sx={{
                      borderRight: '1px solid lightGray',
                      width: '100px',
                      height: '35px',
                      lineHeight: '35px',
                      px: 1
                    }}
                  >
                    価格
                  </Box>
                  <Box
                    sx={{
                      left: '98px',
                      width: '130px',
                      height: '35px',
                      lineHeight: '35px',
                      px: 1
                    }}
                  >
                    ポイント
                  </Box>
                </Box>
              </StickyCell>
              <BodyCellArray data={data.point} indicator={'point'} />
            </StyledTableRow>
            <StyledTableRow>
              <StickyCell colSpan={2}>
                <Box display={'flex'} sx={{ height: '100%' }}>
                  <Box
                    sx={{
                      borderRight: '1px solid lightGray',
                      width: '100px',
                      height: '35px',
                      lineHeight: '35px',
                      px: 1
                    }}
                  >
                    価格
                  </Box>
                  <Box
                    sx={{
                      left: '98px',
                      width: '130px',
                      height: '35px',
                      lineHeight: '35px',
                      px: 1
                    }}
                  >
                    クーポン
                  </Box>
                </Box>
              </StickyCell>
              <BodyCellArray data={data.coupon} indicator={'point'} />
            </StyledTableRow>
            <StyledTableRow sx={{ bgcolor: '#f5f5f5' }}>
              <StickyCell colSpan={2} sx={{ bgcolor: '#f5f5f5' }}>
                <Box display={'flex'} sx={{ height: '100%' }}>
                  <Box
                    sx={{
                      borderRight: '1px solid lightGray',
                      width: '100px',
                      height: '35px',
                      lineHeight: '35px',
                      px: 1
                    }}
                  >
                    価格
                  </Box>
                  <Box
                    sx={{
                      left: '98px',
                      width: '130px',
                      height: '35px',
                      lineHeight: '35px',
                      px: 1
                    }}
                  >
                    楽天スーパーDEAL
                  </Box>
                </Box>
              </StickyCell>
              <BodyCellArray data={data.sale} indicator={'point'} />
            </StyledTableRow>
            <StyledTableRow>
              <StickyCell colSpan={2}>
                <Box display={'flex'} sx={{ height: '100%' }}>
                  <Box
                    sx={{
                      borderRight: '1px solid lightGray',
                      width: '100px',
                      height: '35px',
                      lineHeight: '35px',
                      px: 1
                    }}
                  >
                    タイトル変更
                  </Box>
                  <Box
                    sx={{
                      left: '98px',
                      width: '130px',
                      height: '35px',
                      lineHeight: '35px',
                      px: 1
                    }}
                  >
                    タイトル変更回数
                  </Box>
                </Box>
              </StickyCell>
              <BodyCellArray data={data.title_change} indicator={'title'} />
            </StyledTableRow>
            <StyledTableRow sx={{ bgcolor: '#f5f5f5' }}>
              <StickyCell colSpan={2} sx={{ bgcolor: '#f5f5f5' }}>
                <Box display={'flex'} sx={{ height: '100%' }}>
                  <Box
                    sx={{
                      borderRight: '1px solid lightGray',
                      width: '100px',
                      height: '35px',
                      lineHeight: '35px',
                      px: 1
                    }}
                  >
                    レビュー
                  </Box>
                  <Box
                    sx={{
                      left: '98px',
                      width: '130px',
                      height: '35px',
                      lineHeight: '35px',
                      px: 1
                    }}
                  >
                    レビュー平均
                  </Box>
                </Box>
              </StickyCell>
              <BodyCellArray data={data.review} indicator={'review'} />
            </StyledTableRow>
            <StyledTableRow>
              <StickyCell colSpan={2}>
                <Box display={'flex'} sx={{ height: '100%' }}>
                  <Box
                    sx={{
                      borderRight: '1px solid lightGray',
                      width: '100px',
                      height: '35px',
                      lineHeight: '35px',
                      px: 1
                    }}
                  >
                    レビュー
                  </Box>
                  <Box
                    sx={{
                      left: '98px',
                      width: '130px',
                      height: '35px',
                      lineHeight: '35px',
                      px: 1
                    }}
                  >
                    レビュー数
                  </Box>
                </Box>
              </StickyCell>
              <BodyCellArray data={data.review_num} indicator={'review-num'} />
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TitleChangeModal
        open={open}
        setOpen={setOpen}
        index={modalIndex}
        titleList={data.title}
        titleChangeList={data.title_change}
        itemName={itemName}
      />
    </>
  );
}
