/* eslint-disable */
import * as React from 'react';
import { useEffect, useState, useCallback, useContext, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Box, Typography, Stack } from '@material-ui/core'; // eslint-disable-line
import { DateRangePicker } from 'rsuite';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  changeTempDate,
  confirmDate,
  resetTempDate,
  tempEnd,
  tempStart,
  termArray
} from 'feactures/date/dateSlice';
import { setError } from 'feactures/errorSlice';
import * as moment from 'moment/moment';
import 'moment/locale/ja';
import 'rsuite/dist/rsuite.min.css';
import './DatePickerStyle.css';
import { ReloadLayoutContext } from 'components/templates/dashboard/DashboardLayout';
import {
  fetchKeywordList,
  fetchMeasureAnalysisData,
  getRankingChangeGraphData
} from 'feactures/measure/newMeasureSlice';

moment.locale('ja');

const DatePicker = memo(({ setTerm, page }) => {
  const { reloadStatus, setReloadStatus } = useContext(ReloadLayoutContext);
  const dispatch = useDispatch();
  const containerRef = React.useRef();
  const tempDateStart = useSelector(tempStart);
  const tempDateEnd = useSelector(tempEnd);
  const dateArray = useSelector(termArray);
  const [calenderIsOpen, setCalenderIsOpen] = useState(true);
  const [display, setDisplay] = useState('none');
  const now = new Date();
  const yesterday = now.setDate(now.getDate() - 1);

  var customS = new Date(tempDateStart);
  var customE = new Date(tempDateEnd);

  const predefinedBottomRanges = [];

  const dispatchTable = {
    measureAnalysis: [
      fetchKeywordList(),
      // getRankingChangeGraphData(),
      fetchMeasureAnalysisData()
    ]
  };

  const dispatchHandle = useCallback((currentPage) => {
    return [...dispatchTable[currentPage]].map((r, index) => dispatch(r));
  }, []);

  // ページが変わった時
  useEffect(() => {
    handleSelect(yesterday);
    handleOkClick();
    // dispatchHandle(page);
  }, []);

  useEffect(() => {
    if (reloadStatus == true) {
      //リロードステータスを元に戻す
      setReloadStatus(false);
    }
  }, [reloadStatus]);

  let label = [];
  const list_all_date = (params_start, params_end) => {
    var start = moment(params_start),
      end = moment(params_end);
    while (start.diff(end) <= 0) {
      label.push(start.format('M/D'));
      start.add(1, 'days');
    }
  };

  // 選択した日付から過去30日分の日付を選択
  const handleSelect = (value) => {
    customE = value;
    const end = moment(customE).format('YYYY-MM-DD');
    customS = moment(value).subtract(29, 'days');
    const start = customS.format('YYYY-MM-DD');
    list_all_date(start, end);
    setTerm(label);
    dispatch(
      changeTempDate({
        start: moment(customS).format('YYYY-MM-DD'),
        end: moment(customE).format('YYYY-MM-DD'),
        term: 'custom',
        termArray: label
      })
    );
  };

  const handleOkClick = () => {
    dispatch(setError({ state: null, data: null }));
    dispatch(confirmDate());
    dispatchHandle(page);
    setCalenderIsOpen(false);
    setDisplay('none');
  };

  return (
    <>
      <DateRangePicker
        ranges={predefinedBottomRanges}
        showOneCalendar
        caretAs={ExpandMoreIcon}
        character=" ー "
        onSelect={(value) => handleSelect(value)}
        value={[customS, customE]}
        format="yyyy年MM月dd日"
        placement="bottomEnd"
        renderTitle={(customS) => {
          const year = customS.getFullYear();
          const month = customS.getMonth() + 1;
          return (
            <Typography>
              {year}年{month}月
            </Typography>
          );
        }}
        placeholder="日付を選択してください"
        locale={{
          year: '年',
          month: '月',
          sunday: '日',
          monday: '月',
          tuesday: '火',
          wednesday: '水',
          thursday: '木',
          friday: '金',
          saturday: '土',
          ok: '選択する'
        }}
        container={() => containerRef.current}
        onClick={() => {
          setCalenderIsOpen(true);
          setDisplay('block');
        }}
        open={calenderIsOpen}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: 'white',
          width: '265px',
          maxHeight: '70vh',
          overflowY: 'scroll',
          overflowX: 'hidden',
          display: display,
          position: 'absolute',
          boxShadow: '0 4px 4px rgba(0,0,0,.12), 0 0 10px rgba(0,0,0,.06)',
          borderRadius: 1,
          zIndex: 10
        }}
      >
        <Box ref={containerRef} sx={{ height: '320px', width: '265px' }}></Box>
        <Typography sx={{ px: 2 }}>
          {customS.getFullYear()}年{customS.getMonth() + 1}月{customS.getDate()}
          日から
          {customE.getFullYear()}年{customE.getMonth() + 1}月{customE.getDate()}
          日までの過去
          {dateArray.length}日間
        </Typography>
        <Stack
          direction={'row'}
          spacing={1}
          sx={{
            justifyContent: 'flex-end',
            my: '10px',
            mr: '10px',
            position: 'relative'
          }}
        >
          <Button
            variant="outlined"
            sx={{
              borderColor: 'gray',
              color: 'gray'
            }}
            onClick={() => {
              dispatch(resetTempDate());
              setCalenderIsOpen(false);
              setDisplay('none');
            }}
          >
            キャンセル
          </Button>
          <Button
            variant="contained"
            sx={{
              borderColor: '#718cc6',
              backgroundColor: '#718cc6',
              width: '90px'
            }}
            onClick={() => handleOkClick()}
          >
            選択する
          </Button>
        </Stack>
      </Box>
    </>
  );
});

export default DatePicker;
