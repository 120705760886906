/* eslint-disable */
import { configureStore } from '@reduxjs/toolkit';
// myproductSliceのReducerをimport する
// import myproductReducer from '../feactures/myproduct/myproductSlice';
import fetchMyproductsReducer from 'feactures/product/myproductSlice';
import fetchOtherproductsReducer from 'feactures/product/otherproductSlice';
import fetchMarketReducer from 'feactures/market/marketSlice';
import sectionSliceReducer from 'feactures/section/sectionSlice';
// import sectionSliceReducer from 'feactures/section/sectionSlice';
import channelSliceReducer from 'feactures/channel/channelSlice';
import fetchCategoryReducer from 'feactures/category/categorySlice';
import fetchCategoryGraphReducer from 'feactures/category/categoryGraphSlice.js';
import deleteProductsReducer from 'feactures/product/deleteProductSlice';
import processKPIReducer from 'feactures/monitoring/processKPISlice';
import fetchViewsReducer from 'feactures/monitoring/viewsSlice';
import fetchCVRReducer from 'feactures/monitoring/CVRSlice';
import fetchPriceReducer from 'feactures/monitoring/unitPriceSlice';
import fetchAsinReducer from 'feactures/monitoring/asinSlice';
import fetchKeywords from 'feactures/monitoring/keywordsSlice';
import fetchUser from 'feactures/user/userSlice';
import fetchRmsKey from 'feactures/user/rmsSlice';
import fetchYahooKey from 'feactures/user/yahooKeySlice';
import setError from 'feactures/errorSlice';
import changeAsinDate from 'feactures/monitoring/asinDateSlice';
import fetchExport from 'feactures/monitoring/exportSlice';
import fetchExport_salesByAsin from 'feactures/monitoring/exportSlice';

import fetchCategoryRankReducer from 'feactures/category/categoryRankSlice';
import resigisterProductsReducer from 'feactures/product/registerProductSlice';
import fetchAsyncTrackingReducer, {
  fetchAsyncTrends
} from 'feactures/monitoring/trackingSlice';
import fetchAsyncAdvertiseData from 'feactures/monitoring/advertiseSlice';
import fetchAsyncTrendsReducer from 'feactures/monitoring/trendsSlice';
import fetchMonitorReducer from 'feactures/monitoring/monitoringSlice';
import fetchGroupReducer from 'feactures/monitoring/groupSlice';
import dateReducer from 'feactures/date/dateSlice';
import categoryNo1SalesReducer from 'feactures/category/categoryNo1Slice';
import fetchNewsReducer from 'feactures/user/newsSlice';
import fetchUploadReducer from 'feactures/monitoring/uploadSlice';
import fetchGroupSummaryPost from 'feactures/monitoring/groupSummarySlice';
import monitorAsinReducer from 'feactures/monitoring/monitorAsinSlice';
import surveyReducer from 'feactures/survey/surveySlice';
import favoriteCategoryReducer from 'feactures/category/favoriteCategorySlice';
import fetchCategoryShareReducer from 'feactures/category/categoryShareSlice';
import brandSlice from 'feactures/brand/brandSlice';
import categoryBrandGraphSlice from 'feactures/brand/categoryBrandGraphSlice';
import categoryBrandShareSlice from 'feactures/brand/categoryBrandShareSlice';
import brandRankSlice from 'feactures/brand/brandRankSlice';
import measureSlice from 'feactures/measure/measureSlice';
import productAnalysisSlice from 'feactures/product/productAnalysisSlice';
import validCategoryListSlice from 'feactures/category/validCategoryListSlice';
import deleteUserSlice from 'feactures/user/deleteUserSlice';
import envSlice from 'feactures/env/envSlice';
import planSlice from 'feactures/plan/planSlice';
import overviewSlice from 'feactures/overview/overviewSlice';
import premiumRegisterSlice from 'feactures/plan/premiumRegister';
import pageSlice from 'feactures/section/pageSlice';
import newMeasureSlice from 'feactures/measure/newMeasureSlice';

export const store = configureStore({
  reducer: {
    user: fetchUser,
    myproducts: fetchMyproductsReducer,
    otherproducts: fetchOtherproductsReducer,
    deleteproducts: deleteProductsReducer,
    registerProducts: resigisterProductsReducer,
    currentsection: sectionSliceReducer,
    currentchannel: channelSliceReducer,
    categories: fetchCategoryReducer,
    categorygraph: fetchCategoryGraphReducer,
    categoryshare: fetchCategoryShareReducer,
    processKPI: processKPIReducer,
    views: fetchViewsReducer,
    cvr: fetchCVRReducer,
    price: fetchPriceReducer, //販売価格,
    asins: fetchAsinReducer,
    asinDate: changeAsinDate,
    keywords: fetchKeywords,
    export: fetchExport,
    export_salesByAsin: fetchExport_salesByAsin,
    error: setError,
    categoryrank: fetchCategoryRankReducer, //カテゴリランキング100商品取得
    tracking: fetchAsyncTrackingReducer,
    advertise: fetchAsyncAdvertiseData,
    trends: fetchAsyncTrendsReducer,
    monitoring: fetchMonitorReducer,
    monitoringAsin: monitorAsinReducer,
    group: fetchGroupReducer,
    groupSummary: fetchGroupSummaryPost,
    date: dateReducer,
    categoryNo1: categoryNo1SalesReducer,
    news: fetchNewsReducer,
    upload: fetchUploadReducer,
    rmsKeyStatus: fetchRmsKey,
    yahooKeyStatus: fetchYahooKey,
    survey: surveyReducer,
    favoriteCategories: favoriteCategoryReducer,
    brand: brandSlice,
    categoryBrandGraph: categoryBrandGraphSlice,
    categoryBrandShare: categoryBrandShareSlice,
    brandrank: brandRankSlice,
    measure: measureSlice,
    newMeasureAnalysis: newMeasureSlice,
    productAnalysis: productAnalysisSlice,
    validCategoryList: validCategoryListSlice,
    deleteUsers: deleteUserSlice,
    env: envSlice,
    premiumRegister: premiumRegisterSlice,
    plan: planSlice,
    overview: overviewSlice,
    page: pageSlice
  }
});
