/* eslint-disable*/
import {
  Card,
  CardContent,
  CircularProgress,
  Box,
  Typography,
  IconButton,
  Modal,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemButton
} from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { termArray } from 'feactures/date/dateSlice';

export default function TitleChangeModal({
  open,
  setOpen,
  index,
  titleList,
  titleChangeList,
  itemName
}) {
  const graphloading = false;
  const dateArray = useSelector(termArray);

  const handleClose = () => setOpen(false);

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Card
          sx={{
            width: '35%',
            height: '80%',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            p: 3,
            borderRadius: '10px',
            bgcolor: '#f5f5f5'
          }}
        >
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'start'}
          >
            <Box>
              <Typography variant="h4" my={3} sx={{ fontWeight: 'bold' }}>
                タイトル変更履歴
              </Typography>
            </Box>
            <IconButton sx={{ p: 0, m: 0 }} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          {graphloading ? (
            <Box>
              <p>読み込み中…</p>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Box width="100%" height={'85%'} pt={2}>
                <Typography
                  my={3}
                  sx={{ fontWeight: 'bold', fontSize: '16px' }}
                >
                  {itemName}
                </Typography>
                <Typography
                  my={3}
                  sx={{ fontWeight: 'bold', fontSize: '14px' }}
                >
                  {dateArray[index]}のタイトル変更
                </Typography>
                {index == 0 || titleChangeList[index] == 0 ? (
                  <Typography my={3} sx={{ fontSize: '16px' }}>
                    {dateArray[index]}のタイトル変更はありません
                  </Typography>
                ) : (
                  <Card sx={{ boxShadow: '0 2px lightGray' }}>
                    <CardContent>
                      <Typography mb={3} sx={{ fontSize: '16px' }}>
                        日付：{dateArray[index]}
                      </Typography>
                      <Typography mb={3} sx={{ fontSize: '16px' }}>
                        旧タイトル：
                        {titleList[index - 1] == 0
                          ? 'データ無し'
                          : titleList[index - 1]}
                      </Typography>
                      <Typography sx={{ fontSize: '16px' }}>
                        新タイトル：{titleList[index]}
                      </Typography>
                    </CardContent>
                  </Card>
                )}
              </Box>
            </>
          )}
        </Card>
      </Modal>
    </>
  );
}
