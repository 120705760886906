/* eslint-disable  */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  CircularProgress
} from '@material-ui/core';
import { Box, Grid } from '@material-ui/core';

import { fetchAsyncMyproducts } from 'feactures/product/myproductSlice';
import { fetchAsyncOtherproducts } from 'feactures/product/otherproductSlice';
import {
  registerProducts,
  registerStatus
} from 'feactures/product/registerProductSlice';
import { currentchannel } from 'feactures/channel/channelSlice';
import { errorResMessage, setError } from 'feactures/errorSlice';
import ColorButton from 'components/atoms/button/ColorButton';
import {
  deleteTrackingProduct,
  productIsLoading,
  deleteList,
  registerTrackingProduct,
  fetchMeasureAnalysisData,
  getRankingChangeGraphData
} from 'feactures/measure/newMeasureSlice';

const MeasureAnalysisProductRegister = ({ premiumStatus }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [inputItemId, setInputItemId] = useState('');
  const [inputName, setInputName] = useState('');
  const [inputShopCode, setInputShopCode] = useState('');
  const [flag, setFlag] = useState(true);
  const errorMessage = useSelector(errorResMessage); //errorメッセージ
  const channel = useSelector(currentchannel);
  const deleteProductList = useSelector(deleteList);
  const registerLoading = useSelector(productIsLoading);
  const [itemIdError, setItemIdError] = useState('');
  const [itemNameError, setItemNameError] = useState('');
  const [shopCodeError, setShopCodeError] = useState('');

  useEffect(() => {
    const validateItemId = /[\s\\￥¥]/.test(inputItemId);
    if (validateItemId) {
      setItemIdError('登録できない文字が含まれます');
      setFlag(true);
    } else {
      if (inputItemId && inputName && inputShopCode) {
        setFlag(false);
      } else {
        setFlag(true);
      }
    }
  }, [inputItemId]);

  useEffect(() => {
    const validateItemName = /[\\￥¥]/.test(inputName);
    if (validateItemName) {
      setItemNameError("'\\'や'¥'は入力できません");
      setFlag(true);
    } else {
      if (inputItemId && inputName && inputShopCode) {
        setFlag(false);
      } else {
        setFlag(true);
      }
    }
  }, [inputName]);

  useEffect(() => {
    const validateShopCode = /[\s\\￥¥]/.test(inputShopCode);
    if (validateShopCode) {
      setShopCodeError('登録できない文字が含まれます');
      setFlag(true);
    } else {
      if (inputItemId && inputName && inputShopCode) {
        setFlag(false);
      } else {
        setFlag(true);
      }
    }
  }, [inputShopCode]);

  const changeItemId = (e) => {
    dispatch(setError(''));
    setItemIdError('');
    setInputItemId(e.target.value);
  };

  const changeItemName = (e) => {
    dispatch(setError(''));
    setItemNameError('');
    setInputName(e.target.value);
  };

  const changeShopCode = (e) => {
    dispatch(setError(''));
    setShopCodeError('');
    setInputShopCode(e.target.value);
  };

  const registerHandle = async () => {
    await dispatch(
      registerTrackingProduct({
        item_id: inputItemId,
        item_name: inputName,
        shop_code: inputShopCode
      })
    )
      .then(unwrapResult) //registerProductsで登録が成功（fullfilled）のときはモーダルを閉じる
      .then(() => {
        dispatch(fetchMeasureAnalysisData());
        // dispatch(getRankingChangeGraphData());
        setInputItemId('');
        setInputName('');
        setInputShopCode('');
        handleClose();
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDeleteProduct = async () => {
    if (
      window.confirm(
        //eslint-disable-line
        '削除しますか？　※選択された商品のデータは今後蓄積されません'
      )
    ) {
      await dispatch(deleteTrackingProduct())
        .then(unwrapResult)
        .then(() => {
          dispatch(fetchMeasureAnalysisData());
          // dispatch(getRankingChangeGraphData());
        });
    }
  };

  const handleClose = () => {
    dispatch(setError('')); //表示されているエラーをリセット
    setOpen(false);
  };

  return (
    <>
      <Grid container>
        <Button
          variant="contained"
          color="detailButton"
          onClick={handleClickOpen}
          sx={{ mr: 2 }}
          disabled={!premiumStatus}
        >
          商品登録
        </Button>
        <Button
          disabled={deleteProductList.length == 0}
          variant="contained"
          color="detailButton"
          onClick={handleDeleteProduct}
        >
          商品削除
        </Button>
      </Grid>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>商品登録</DialogTitle>
        <DialogContent
          sx={{
            pt: '10px !important',
            display: 'flex',
            flexDirection: 'column',
            width: '500px'
          }}
        >
          <TextField
            id="outlined-search"
            label={'ショップコード'}
            value={inputShopCode}
            onChange={changeShopCode}
            sx={{ my: 1 }}
            fullWidth
          />
          {shopCodeError != '' && (
            <Typography color="red">{shopCodeError}</Typography>
          )}

          <TextField
            id="outlined-search"
            label={
              channel == 1 ? 'ASIN' : channel == 2 ? '商品管理番号' : '商品番号'
            }
            value={inputItemId}
            onChange={changeItemId}
            sx={{ my: 1 }}
            fullWidth
          />
          {itemIdError != '' && (
            <Typography color="red">{itemIdError}</Typography>
          )}

          <TextField
            id="outlined-search"
            label={'商品タイトル'}
            value={inputName}
            onChange={changeItemName}
            sx={{ my: 1 }}
            fullWidth
          />
          {itemNameError != '' && (
            <Typography color="red">{itemNameError}</Typography>
          )}

          {errorMessage && (
            <Typography color="red" sx={{ my: 1 }}>
              {errorMessage}
            </Typography>
          )}
          <Typography sx={{ my: 1 }}>
            {/* 複数商品を登録する際は、カンマで区切ってください
            （最大で10個まで登録可能）
            <br /> */}
            {channel === 2 && (
              <>
                ▼ショップコード・商品管理番号の確認方法
                <br />
                該当商品の店舗ページを表示してください。
                <br />
                ↓
                <br />
                URLの2階層目をご確認下さい。
                <br />
                https://item.rakuten.co.jp/&#123;ショップコード&#125;/&#123;商品管理番号&#125;/
                <br />
                <br />
                ▼商品名の記入方法
                <br />
                商品名は販売中の商品タイトルを省略せずにそのまま記入してください。
              </>
            )}
          </Typography>
        </DialogContent>
        <DialogActions>
          {errorMessage === '登録可能な商品数を超えています' ||
          errorMessage === '商品登録時にエラーが発生しました' ? (
            <Button onClick={handleClose} color="primary">
              OK
            </Button>
          ) : (
            <>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <ColorButton disabled={flag} onClick={registerHandle}>
                {registerLoading ? <CircularProgress size={15} /> : '登録'}
              </ColorButton>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
export default MeasureAnalysisProductRegister;
