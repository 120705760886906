/* eslint-disable*/
import {
  Card,
  useTheme,
  CircularProgress,
  Box,
  Typography,
  IconButton,
  Modal
} from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { termArray } from 'feactures/date/dateSlice';
import 'chartjs-adapter-moment';
import {
  seoGraphData,
  seoGraphLoading
} from 'feactures/measure/newMeasureSlice';

export default function KeywordRankingGraphModal({ open, setOpen, itemName }) {
  const graphloading = useSelector(seoGraphLoading);
  const theme = useTheme();
  const term = useSelector(termArray);
  const graphdata_list = useSelector(seoGraphData);

  const handleClose = () => setOpen(false);

  const graphColorList = ['#FF89A2', '#47BFBF', '#FFCC53'];

  const data = {
    datasets: graphdata_list.map((data, index) => {
      return {
        label: data.label,
        radius: 0,
        barThickness: 'flex',
        fill: false,
        backgroundColor: 'transparent',
        // backgroundColor: graphColorList[index % graphColorList.length],
        hoverBackgroundColor: graphColorList[index % graphColorList.length],
        borderColor: graphColorList[index % graphColorList.length],
        borderWidth: 2,
        data: data.data,
        maxBarThickness: 20,
        pointRadius: 4,
        pointStyle: 'circle',
        pointBorderColor: graphColorList[index % graphColorList.length],
        pointBackgroundColor: 'transparent',
        lineTension: 0.25
      };
    }),
    labels: term
  };

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false
        },
        title: {
          display: false
        },
        ticks: {
          font: {
            size: '10px'
          }
        }
      },
      y: {
        ticks: {
          fontColor: theme.palette.text.secondary,
          font: {
            family: "'M PLUS 1p', sans-serif"
          },
          callback: function (value, index, values) {
            let returnValue = value + '位';
            return returnValue;
          }
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: theme.palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: theme.palette.divider
        }
      }
    },
    plugins: {
      tooltip: {
        titleFont: {
          family: "'M PLUS 1p', sans-serif"
        },
        bodyFont: {
          family: "'M PLUS 1p', sans-serif"
        }
      },
      title: {
        display: true,
        text: '検索順位',
        position: 'left'
      },
      legend: {
        labels: {
          boxWidth: 40,
          boxHeight: 10
          //   usePointStyle: true
          //   pointStyle: 'circle'
        },
        position: 'top'
      },
      datalabels: {
        display: false
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: true,
    cornerRadius: 20,
    layout: {
      maxwidth: '100px',
      padding: {
        left: 0,
        right: 20,
        top: 0,
        bottom: 0
      }
    }
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Card
          sx={{
            width: '80vw',
            height: '90vh',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            p: 3,
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'column' // コンテンツを縦に配置
          }}
        >
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'start'}
          >
            <Box>
              <Typography fontSize={'15px'} mb={1} sx={{ fontWeight: 'bold' }}>
                キーワード検索順位
              </Typography>
              <Typography fontSize={'15px'} mb={1} sx={{ fontWeight: 'bold' }}>
                {itemName}のキーワードランキング推移
              </Typography>
            </Box>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          {graphloading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexGrow={1}
            >
              <p>グラフの読み込み中…</p>
              <CircularProgress />
            </Box>
          ) : (
            <Box width="100%" flexGrow={1} pt={1}>
              <Line
                width="100%"
                height={'100%'}
                data={data}
                options={options}
              />
            </Box>
          )}
        </Card>
      </Modal>
    </>
  );
}
